<template>
  <nav aria-label="main navigation" class="navbar is-fixed-top is-primary" role="navigation">
    <div class="navbar-brand">
      <router-link class="navbar-item" to="/admin/dashboard">
        <strong>OCM 2023 Admin</strong>
      </router-link>

      <a aria-expanded="false"
         aria-label="menu"
         class="navbar-burger burger"
         data-target="navbarBasicExample"
         @click="navigationDrawerOpen = !navigationDrawerOpen"
         role="button" :class="{'is-active': navigationDrawerOpen}">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div class="navbar-menu" id="navbarBasicExample" :class="{'is-active': navigationDrawerOpen}">
      <div class="navbar-start">
        <router-link class="navbar-item" to="/regimento">Regimento</router-link>
      </div>
      <div class="navbar-end">
        <div class="navbar-item">
          <div class="buttons">
            <a class="button is-outlined is-light" @click.prevent="logout">Sair</a>
          </div>
        </div>
      </div>

    </div>
  </nav>
</template>

<script>
import { captureException } from "@sentry/vue"

export default {
  name: "AdminHeader",
  data() {
    return {
      navigationDrawerOpen: false
    }
  },
  methods: {
    logout() {
      this.$http.post('signOut').then(() => {
        this.$store.dispatch('authLogout').then(() => {
          this.$router.push({path: '/login'})
        })
      }).catch(error => captureException(error))
    }
  }
}
</script>

<style scoped>

</style>
