export class Adviser {
    constructor(cpf, email, inep, name, activationStatus, primaryPhoneNumber, secondaryPhoneNumber) {
        this.cpf = cpf
        this.email = email
        this.inep = inep
        this.name = name
        this.activationStatus = activationStatus
        this.primaryPhoneNumber = primaryPhoneNumber
        this.secondaryPhoneNumber = secondaryPhoneNumber
    }
}