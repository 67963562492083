<template>
  <footer class="footer">
    <div class="content has-text-centered">
      <p>
        Olimpíada Cearense de Matemática 2024<br>
        Departamento de Matemática - Universidade Federal do Ceará<br>
        ocm@mat.ufc.br
      </p>
    </div>
  </footer>
</template>

<script>
  export default {
    name: "AppFooter"
  }
</script>

<style lang="sass" scoped>
    footer
        padding-bottom: 3rem
</style>
