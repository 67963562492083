<template>
  <div id="app"
       :class="{'has-navbar-fixed-top': this.$store.getters.isAdviserSignedIn || ['signup', 'regimento', 'premiacao', 'publishedStudents', 'index'].includes(this.$route.name)}">
    <!--        <template v-if="this.$route.path !== '/'">-->
    <AppHeader v-if="!this.$store.getters.isAdminSignedIn"></AppHeader>
    <AdminHeader v-else></AdminHeader>
    <!--        </template>-->
    <main role="main">
      <router-view></router-view>
    </main>
    <AppFooter></AppFooter>
  </div>
</template>

<script>
import AppFooter from "@/components/AppFooter"
import AppHeader from "@/components/AppHeader"
import AdminHeader from "@/components/admin/AdminHeader"
import {ToastProgrammatic as Toast} from 'buefy'

export default {
  name: 'app',
  components: {
    AppHeader, AppFooter, AdminHeader
  },
  beforeCreate() {
    this.$http.interceptors.response.use(undefined, err => {
      return new Promise((resolve, reject) => {
        if ([400, 401].includes(err.response.status) && err.response.config && !err.response.config.__isRetryRequest) {
          if (['sessionExpired', 'unauthorized', 'invalidAuthorizationToken'].includes(err.response.data.errorCode)) {
            Toast.open('Sua sessão expirou')

            if (err.response.data.errorCode !== 'invalidAuthorizationToken') {
              localStorage.removeItem('user-token')
              localStorage.removeItem('restriction')
              this.$store.commit('authNone')
            }
            // if you ever get an unauthorized, logout the user
            if (this.$store.getters.token) {
              this.$store.dispatch('authLogout').then(() => {
                this.$router.push({path: '/login'})
              })
            } else {
              this.$router.push({path: '/login'})
            }
          }
        }
        reject(err.response)
      })
    })
  }
}
</script>

<style lang="sass">
@import 'variable'

html, body
  .has-navbar-fixed-top
    @media screen and (min-width: $desktop)
      //padding-top: 64px !important
      padding-top: 52px !important

  min-height: 100vh

  #app
    min-height: 100vh
    display: flex
    flex-direction: column

    main
      flex: 1
</style>
