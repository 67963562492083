export default {
    'schoolNotFound': 'Nenhuma escola foi encontrada com este código INEP',
    'inepAlreadyRegistered': 'Este código INEP já está cadastrado',
    'credentialsFailure': 'Usuário ou senha não conferem',
    'sessionExpired': 'Sua sessão expirou',
    'unauthorized': 'Acesso a essa página requer autenticação',
    'payloadValidationError': 'Alguma informação no formulário é inválida',
    'noFileUploaded': 'Nenhum arquivo enviado',
    'uploadFailure': 'Ocorreu um erro ao enviar o arquivo',
    'unhandledException': 'Ocorreu um erro interno no servidor',
    'adviserJustAddedNewStudents': 'Coordenador inscreveu novos alunos',
    'emptyStudentList': 'A lista de alunos está vazia',
    'apiNotFound': 'Erro interno. Contate o time de desenvolvimento.',
    'enrollStudentPermissionDisabled': 'Fora do período de inscrição.',
    'studentLevelOverflow': 'A quantidade de alunos inscritos para o seguinte nível é maior do que a permitida: Nível '
}
