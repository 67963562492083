<template>
  <div class="is-widescreen has-navbar-fixed-top">
    <section class="hero">
      <div class="hero-body container">
        <div class="container">
          <h1 class="title">
            Olimpíada Cearense de Matemática 2024
          </h1>
          <h2 class="subtitle">
            Provas em 15 de junho de 2024,<br>
            Colégio Farias Brito Pré-Vestibular Aldeota e Colégio Farias Brito Jovem Aldeota
          </h2>
        </div>
      </div>
    </section>
    <section class="section container">
      <div class="columns">
        <div class="column is-two-thirds content">
          <p>Prezado Coordenador, Prezada Coordenadora,</p>

          <p>Esta é a página para inscrição da sua escola na Edição 2024 da
            Olimpíada Cearense de Matemática - OCM2024. Todo o processo de
            inscrição ocorrerá exclusivamente por esta página, desde a inserção
            dos participantes até o envio do comprovante de pagamento das
            inscrições, passando pelas substuições que a escola desejar
            efetuar. Observe, pra isso, as datas do calendário da OCM2024.</p>

          <p>O código INEP da escola será o login/usuário da escola neste
            sistema. Clique no botão "Cadastrar" se a sua escola não estiver
            cadastrada e for seu desejo proceder com o cadastro.</p>
          <p class="has-text-centered">
            <router-link class="button is-primary" to="/signup">Cadastrar</router-link>
          </p>

          <p class="has-text-weight-bold">Um mesmo coordenador poderá cadastrar mais de uma escola da sua rede
            de ensino, mas precisará cadastrar escola por escola, usando a código
            INEP da unidade a ser cadastrada.</p>

          <p>A Coordenação da OCM2024 deseja todo sucesso a cada uma dos
            participantes da sua escola.</p>
        </div>
        <div class="column">
          <div class="box has-background-light">
            <form @submit.prevent="login">
              <b-field label="Código INEP"
                       :type="{'is-danger': errors.has('inep')}"
                       :message="{'INEP de 8 dígitos é obrigatório': errors.first('inep')}">
                <b-input type="number"
                         name="inep"
                         v-model="inep"
                         v-validate="'required|length:8'"></b-input>
              </b-field>

              <b-field label="Senha"
                       :type="{'is-danger': errors.has('password')}"
                       :message="{'Senha de no mínimo 8 caracteres é obrigatória': errors.first('password')}">
                <b-input password-reveal
                         type="password"
                         name="password"
                         autocomplete="off"
                         v-model="password"
                         v-validate="'required|min:8'"></b-input>
              </b-field>

              <div class="field is-grouped is-grouped-right">
                <div class="control">
                  <router-link class="button is-text" tag="a" to="/signup">Não tenho cadastro
                  </router-link>
                </div>
                <div class="control">
                  <button class="button is-primary"
                          :class="{'is-loading': isSignInRequestInProgress}"
                          type="submit">Entrar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { ToastProgrammatic as Toast } from 'buefy'
import Error from '../error'
import sha256 from 'crypto-js/sha256'

export default {
  name: "Login",
  data() {
    return {
      inep: undefined,
      password: undefined,
      isSignInRequestInProgress: false
    }
  },
  methods: {
    login() {
      this.$validator.validateAll().then(result => {
        if (result) {
          const adviser = {
            inep: this.inep,
            password: sha256(this.password).toString()
          }
          this.isSignInRequestInProgress = true
          this.$store.dispatch('authRequest', adviser).then(() => {
            localStorage.setItem('inep', +this.inep)
            this.$store.commit('setInep', +this.inep)
            this.$store.dispatch('getSchool').then(() => {
              this.$router.push('/home')
              this.isSignInRequestInProgress = false
            })
          }).catch((error) => {
            if (error.data) {
              Toast.open(Error[error.data.errorCode])
            }
          }).then(() => {
            this.isSignInRequestInProgress = false
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
