<template>
  <nav aria-label="main navigation" class="navbar is-fixed-top" role="navigation">
    <div class="navbar-brand">
      <router-link class="navbar-item" to="/">
        <strong class="has-text-primary">OCM 2024</strong>
      </router-link>

      <a aria-expanded="false"
         aria-label="menu"
         class="navbar-burger burger"
         data-target="navbarBasicExample"
         @click="navigationDrawerOpen = !navigationDrawerOpen"
         role="button" :class="{'is-active': navigationDrawerOpen}">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
    <div class="navbar-menu" id="navbarBasicExample" :class="{'is-active': navigationDrawerOpen}">
      <div class="navbar-start">
        <router-link class="navbar-item" to="/home" v-if="isAdviserSignedIn">Início</router-link>
        <router-link class="navbar-item" to="/regimento">Regimento</router-link>
        <router-link class="navbar-item" to="/published-students">Lista de inscritos</router-link>
        <router-link class="navbar-item" to="/premiacao">Premiação</router-link>
        <template v-if="isAdviserSignedIn && isAdviserActive">
          <router-link class="navbar-item" to="/enroll/spreadsheet" v-if="this.$store.getters.canEnroll">
            Enviar planilha
          </router-link>
          <router-link class="navbar-item" to="/payment" v-if="this.$store.getters.canSubmitPayment">
            Enviar comprovantes de pagamento
          </router-link>
          <router-link class="navbar-item" to="/list">Seus Alunos inscritos</router-link>
        </template>
      </div>
      <div class="navbar-end" v-if="!isAdviserSignedIn">
        <div class="navbar-item">
          <div class="buttons">
            <router-link class="button is-primary" to="/signup">Cadastrar</router-link>
            <router-link class="button is-light" to="/login">Entrar</router-link>
          </div>
        </div>
      </div>
      <div class="navbar-end" v-else>
        <div class="navbar-item">
          <p class="has-text-right" v-if="isSchoolInfoLoaded">
            <strong>{{ this.$store.state.adviser.inep }}</strong> - {{ this.$store.state.school.name }}<br>
            {{ this.$store.state.adviser.name }}
          </p>
          <p v-else>
            Carregando...
          </p>
        </div>
        <div class="navbar-item">
          <div class="buttons">
            <a class="button is-outlined is-danger" @click.prevent="logout">Sair</a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>

import {captureException} from "@sentry/vue"

export default {
  name: "AppHeader",
  data() {
    return {
      navigationDrawerOpen: false
    }
  },
  methods: {
    logout() {
      this.$http.post('signOut').then(() => {
        this.$store.dispatch('authLogout').then(() => {
          this.$router.push({path: '/login'})
        }).catch(error => captureException(error))
      })
    }
  },
  mounted() {
    if (this.$store.getters.isAdviserSignedIn) {
      this.$store.dispatch('getSchool').then(response => {
        if (!response.data.status) {
          console.log(Error[response.data.errorCode])
        }
      }).catch(error => {
        captureException(error)
        this.logout()
      })
    }
  },
  computed: {
    isAdviserSignedIn() {
      return this.$store.getters.isAdviserSignedIn
    },
    isAdviserActive() {
      return this.$store.getters.isAdviserActive
    },
    isSchoolInfoLoaded() {
      return this.$store.getters.isSchoolInfoLoaded
    }
  }
}
</script>

<style lang="sass" scoped>
</style>
