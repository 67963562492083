import Vue from 'vue'
import Buefy from 'buefy'
import router from '@/router'
import store from './store'
import App from './App.vue'
import VeeValidate from 'vee-validate'
import axios from './axiosOcm'
import VueAxios from 'vue-axios'
import * as Sentry from '@sentry/vue'
import './variable.sass'
import VueGtag from 'vue-gtag'

Vue.config.productionTip = false
Vue.use(Buefy)
Vue.use(VeeValidate)
Vue.use(VueGtag, {
  config: {id: 'GTM-MDH3F45W'}
}, router)
Vue.use(VueAxios, axios)

Sentry.init({
  Vue,
  dsn: "https://6288125d4a0c43f2a1905ca82f649fd2@o1048859.ingest.sentry.io/6030237",
  integrations: [
    Sentry.browserTracingIntegration({router}),
    Sentry.replayIntegration()
  ],
  tracePropagationTargets: ["localhost", "ocm-container.mat.br", "ocm.mat.br", /^\//],
  tracesSampleRate: 1.0,
  environment: process.env.NODE_ENV,
  release: process.env.SENTRY_RELEASE,
});

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
/*
new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "ocm-container.mat.br", "ocm.mat.br", /^\//],
    }),
 */
