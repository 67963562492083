import Vue from 'vue'
import Vuex from 'vuex'
import VueAxios from 'vue-axios'
import axios from '../axiosOcm'
import {School} from "@/store/school"
import {Adviser} from "./adviser"
import * as Sentry from "@sentry/browser"

Vue.use(Vuex)
Vue.use(VueAxios, axios)

export default new Vuex.Store({
    state: {
        inep: +localStorage.getItem('inep') || undefined,
        hamburgerActive: false,
        adviser: undefined,
        school: new School(),
        token: localStorage.getItem('user-token') || '',
        status: '',
        appPermissions: {
            enrollStudents: false,
            editStudents: false,
            deleteStudents: false,
            submitPayment: false
        },
        isAdminSignedIn: !!localStorage.getItem('restriction') || false
    },
    mutations: {
        toggleHamburger(state) {
            state.hamburgerActive = !state.hamburgerActive
        },
        signIn(state, adviser) {
            state.adviser = adviser
            Sentry.setUser({
                id: adviser.cpf,
                username: adviser.name,
                email: adviser.email
            })
        },
        setSchoolBasicInfo(state, school) {
            state.school.name = school.name
            state.school.inep = school.inep
        },
        setSchoolSummaryInfo(state, summary) {
            state.school.studentCount = +summary.studentCount
            state.school.examTotalAmount = +summary.examTotalAmount
            state.school.examApplicationCity = summary.examApplicationCity
            state.school.paymentDone = summary.paymentDone
        },
        authRequest(state) {
            state.status = 'loading'
        },
        authSuccess(state, data) {
            state.status = 'success'
            state.token = data.token
            state.isAdminSignedIn = data.isAdminSignedIn || false
            if (data.isAdminSignedIn) {
                Sentry.setUser({username: data.user})
            }
        },
        authError(state) {
            state.status = 'error'
        },
        authNone(state) {
            state.status = ''
            state.isAdminSignedIn = false
            state.token = ''
            Sentry.configureScope(scope => scope.setUser(null))
        },
        setInep(state, inep) {
            state.inep = inep
        },
        updatePermissions: (state, permissionList) => {
            for (const permission of permissionList) {
                state.appPermissions[permission.key] = permission.enabled
            }
        }
    },
    getters: {
        isAdviserSignedIn: state => !!state.token && !state.isAdminSignedIn,
        isSchoolInfoLoaded: state => state.adviser !== undefined,
        authStatus: state => state.status,
        isAdviserActive: state => state.adviser !== undefined && state.adviser.activationStatus === 'APPROVED',
        isPaymentDone: state => state.school.paymentDone,
        inep: state => state.inep,
        token: state => state.token,
        canEnroll: state => state.appPermissions.enrollStudents,
        canEdit: state => state.appPermissions.editStudents,
        canDelete: state => state.appPermissions.deleteStudents,
        canSubmitPayment: state => state.appPermissions.submitPayment,
        isAdminSignedIn: state => state.isAdminSignedIn
    },
    actions: {
        authRequest({commit}, adviser) {
            return new Promise((resolve, reject) => {
                commit('authRequest')
                Vue.axios.post('adviser/signIn', {
                    payload: adviser
                }).then(response => {
                    if (response.data.status) {
                        const token = response.data.authorization.access_token
                        const tokenType = response.data.authorization.token_type
                        localStorage.setItem('user-token', `${tokenType} ${token}`)
                        commit('authSuccess', {token})
                        axios.defaults.headers.common['Authorization'] = `${tokenType} ${token}`
                        resolve(response)
                    } else {
                        commit('authError')
                        reject(response)
                    }
                }).catch(error => {
                    commit('authError')
                    localStorage.removeItem('user-token')
                    localStorage.removeItem('inep')
                    reject(error)
                })
            })
        },
        authLogout({commit}) {
            return new Promise(resolve => {
                commit('authNone')
                localStorage.removeItem('user-token')
                localStorage.removeItem('inep')
                localStorage.removeItem('restriction')

                delete Vue.axios.defaults.headers.common['Authorization']
                resolve(true)
            })
        },
        getSchool({commit}) {
            return new Promise((resolve, reject) => {
                const inep = localStorage.getItem('inep')

                if (inep) {
                    Vue.axios.get(`school/${inep}`).then(response => {
                        if (response.status) {
                            const school = {
                                inep: response.data.school.inep,
                                name: response.data.school.name
                            }
                            commit('setSchoolBasicInfo', school)

                            const adviser = new Adviser(
                                response.data.adviser.cpf,
                                response.data.adviser.email,
                                response.data.adviser.inep,
                                response.data.adviser.name,
                                response.data.adviser.activationStatus,
                                response.data.adviser.primaryPhoneNumber,
                                response.data.adviser.secondaryPhoneNumber
                            )
                            commit('signIn', adviser)
                            resolve(response)
                        } else {
                            reject(response.data)
                        }
                    }).catch(error => {
                        reject(error)
                    })
                } else {
                    reject(false)
                }
            })
        },
        updatePermissions: ({commit}) => {
            Vue.axios.get('app/getAppPermissions').then(response => {
                if (response.data.status) {
                    commit('updatePermissions', response.data.permissionList)
                }
            })
        },
        updateSummary({commit}) {
            Vue.axios.get(`student/getStudentsSummaryInformation/${this.getters.inep}`).then(response => {
                if (response.data.status) {
                    let schoolSummary = {
                        examApplicationCity: response.data.studentsSummaryInformation.examApplicationCity,
                        examTotalAmount: response.data.studentsSummaryInformation.examTotalAmount,
                        studentCount: response.data.studentsSummaryInformation.studentCount,
                        paymentDone: response.data.studentsSummaryInformation.paymentStatus === 'PAID' || false
                    }
                    commit('setSchoolSummaryInfo', schoolSummary)
                }
            })
        }
    }
})
