import axios from 'axios'
import 'axios-response-logger'

const requestRoot = process.env.NODE_ENV !== 'production' ? 'http://ocm-container.mat.br/' : 'https://api.ocm.mat.br/'
const token = localStorage.getItem('user-token')

const axiosOCM = axios.create({
    baseURL: requestRoot,
    withCredentials: true
})

if (token) {
    axiosOCM.defaults.headers.common['Authorization'] = token
}

export default axiosOCM